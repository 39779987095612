@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --color-White: 255 255 255;
    --color-Black: 17 17 17;
    --color-XXLightGray: 250 250 250;
    --color-XLightGray: 244 245 245;
    --color-LightGray: 232 234 234;
    --color-MidGray: 197 202 204;
    --color-DarkGray: 140 150 153;
    --color-XDarkGray: 82 97 101;
  }
}

@layer utilities {
  .text-regular32 {
    @apply text-[32px] leading-normal;
  }
  .text-regular24 {
    @apply text-[24px] leading-6;
  }
  .text-book20 {
    @apply text-[20px] leading-[30px];
  }
  .text-book16 {
    @apply text-[16px] leading-6;
  }
  .text-book12 {
    @apply font-book text-[12px] leading-4;
  }
  .text-regular14 {
    @apply text-[14px] font-normal leading-5;
  }
  .text-medium12 {
    @apply text-[12px] font-medium leading-6;
  }
  .text-medium16 {
    @apply text-[16px] font-medium leading-6;
  }
  .text-medium14 {
    @apply text-[14px] font-medium leading-5;
  }

  .text-medium20 {
    @apply text-[20px] font-medium leading-5;
  }
}

@layer components {
  #__next {
    @apply flex min-h-screen flex-col;
  }

  .link {
    @apply text-frecBlack decoration-frecNeon font-medium no-underline decoration-2 underline-offset-4 hover:underline;
  }

  ::selection {
    @apply bg-frecNeon;
  }

  .bg-frecNeon::selection {
    @apply text-frecWhite;
    @apply bg-frecBlue;
  }

  .text-frecWhite::selection {
    @apply bg-frecBlue;
  }

  body {
    @apply ring-frecBlue bg-frecBeige;
  }

  *:focus {
    @apply !border-frecBlue !ring-frecBlue;
  }

  :focus-visible {
    @apply outline-frecBlue;
  }

  h2,
  .h2 {
    @apply text-balance text-[30px] lg:text-[40px] lg:leading-tight;
  }

  .excerpt p {
    @apply text-frecBlack/70 line-clamp-2;
  }
}

html {
  height: 100%;
  overscroll-behavior-y: none;
}

body {
  margin: 0;
  font-family:
    "abcWhyte",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-attachment: fixed;
  font-weight: 300;
  transition: background-color 200ms;
  color: #111111;
}

button,
input,
optgroup,
select,
textarea {
  font-family:
    "abcWhyte",
    -apple-system,
    BlinkMacSystemFont,
    "Segoe UI",
    "Roboto",
    "Oxygen",
    "Ubuntu",
    "Cantarell",
    "Fira Sans",
    "Droid Sans",
    "Helvetica Neue",
    sans-serif !important;
  font-weight: 300;
}

input.blue-cursor {
  cursor:
    url("/webstatic/svg/text-cursor.svg") 8 30,
    text;
}

.bgTileTop {
  background-image: linear-gradient(to bottom, transparent, #11111177),
    url("/webstatic/images/tile.png");
  background-repeat: no-repeat, repeat;
  transform-origin: top;
  opacity: 0.5;
}
.bgTileTopSkew {
  transform: perspective(400px) rotateX(-15deg);
}

.bgTileBottom {
  background-image: linear-gradient(to top, transparent, #11111177),
    url("/webstatic/images/tile.png");
  background-repeat: no-repeat, repeat;
  transform-origin: bottom;
  opacity: 0.5;
}
.bgTileBottomSkew {
  transform: perspective(400px) rotateX(15deg);
}

.bgZeroCommission {
  background-image: url("/webstatic/images/zero-commission-bg.png");
  background-repeat: no-repeat, repeat;
  background-size: cover;
}

.animation-cursor {
  cursor:
    url("/webstatic/svg/scroll-cursor.svg") 21 0,
    auto;
}

.translate3d {
  transform: translate3d(0, 0, 0);
}

.teamWindowPane {
  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(10.5px);
}

.frec-shadow {
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
}

.screenshot-shadow {
  box-shadow: -2px 5px 15px rgba(0, 0, 0, 0.1);
}

.button-shadow {
  box-shadow: 0px 2px 16px rgba(0, 0, 0, 0.1);
}

.button-shadow:hover {
  box-shadow: 0px 4px 24px rgba(0, 0, 0, 0.25);
}

.rotating-chevron {
  &[data-rotate] {
    transform: rotate(45deg);
  }
}

.hide-inactive {
  &[data-inactive] {
    opacity: 0;
    cursor: default;
  }
}

.svb-animate-gradient-text {
  animation: svb-gradient-text-animation 5s linear infinite normal;
  background-size: 200%;
}

@keyframes svb-gradient-text-animation {
  0% {
    background-position: 0%;
  }
  25% {
    background-position: 50%;
  }
  50% {
    background-position: 100%;
  }
  75% {
    background-position: 150%;
  }
  100% {
    background-position: 200%;
  }
}

@font-face {
  font-family: "abcWhyte";
  src: url("/webstatic/fonts/abcWhyte/ABCWhyte-Book.woff2") format("woff2");
  font-weight: 300; /* Light / Book */
  font-style: normal;
}

@font-face {
  font-family: "abcWhyte";
  src: url("/webstatic/fonts/abcWhyte/ABCWhyte-Regular.woff2") format("woff2");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "abcWhyte";
  src: url("/webstatic/fonts/abcWhyte/ABCWhyte-Medium.woff2") format("woff2");
  font-weight: 500; /* Medium */
  font-style: normal;
}

@font-face {
  font-family: "abcWhyte";
  src: url("/webstatic/fonts/abcWhyte/ABCWhyte-Bold.woff2") format("woff2");
  font-weight: 600; /* Bold */
  font-style: normal;
}

@font-face {
  font-family: "abcWhyteMono";
  src: url("/webstatic/fonts/abcWhyteSemiMono/ABCWhyteSemi-Mono-Regular.woff2")
    format("woff2");
}

.animate-fast {
  animation-duration: 250ms !important;
}

.animate-once {
  animation-iteration-count: 1 !important;
}

.dotted-h,
.dark .dotted-h.light {
  border-image-source: repeating-linear-gradient(
    to right,
    #888 0px,
    #888 1px,
    rgba(255, 255, 255, 0) 1px,
    rgba(255, 255, 255, 0) 6px
  );
  border-image-slice: 1;
}
.dotted-v,
.dark .dotted-v.light {
  border-image-source: repeating-linear-gradient(
    to bottom,
    #888 0px,
    #888 1px,
    rgba(255, 255, 255, 0) 1px,
    rgba(255, 255, 255, 0) 6px
  );
  border-image-slice: 1;
}

.dark .dotted-h {
  border-image-source: repeating-linear-gradient(
    to right,
    #eee 0px,
    #eee 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 6px
  );
}
.dark .dotted-v {
  border-image-source: repeating-linear-gradient(
    to bottom,
    #eee 0px,
    #eee 1px,
    rgba(0, 0, 0, 0) 1px,
    rgba(0, 0, 0, 0) 6px
  );
}

/* Wordpress content styles */

.wordpress strong {
  @apply font-medium;
}

.wordpress em {
  @apply text-frecBlack/80;
}

.wordpress .wp-block-heading {
  @apply mb-4;
}

.wordpress h2.wp-block-heading {
  @apply font-regular mt-12 text-[32px];
}

.wordpress .has-large-font-size {
  @apply text-[32px];
}

.wordpress h3.wp-block-heading {
  @apply font-regular text-frecBlack/80 mt-8 text-2xl;
}

.wordpress h4.wp-block-heading {
  @apply font-regular text-frecBlack/80 mt-8 text-xl;
}

.wordpress a {
  color: #000aff; /* Blue */
}

.wordpress a:hover,
.wordpress a:active {
  text-decoration: underline;
}

.wordpress p {
  font-size: 16px;
  font-weight: 350;
  margin-top: 1rem;
  margin-bottom: 1.5rem;
}

.wordpress blockquote {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
  border-left: 4px solid #e9e5dd; /* Dark Beige */
  padding: 0 0 0 24px;
  color: #11111188;
  font-style: normal;
}

.wordpress blockquote p {
  font-size: 20px;
}

.wordpress blockquote cite {
  display: block;
  white-space: nowrap;
  font-size: 14px;
  font-weight: 500;
  font-style: normal;
  text-align: right;
  text-transform: uppercase;
}

.wordpress blockquote cite::before {
  content: "—  ";
}

.wordpress .has-x-small-font-size {
  font-size: 14px;
}

.wordpress ul {
  @apply my-4 ml-9 list-disc;
}

.wordpress li {
  @apply my-2;
}

.wordpress ul > li::marker {
  content: url("/webstatic/images/square-bullet.svg");
  vertical-align: middle;
}

.wordpress ol {
  @apply my-4 ml-6;
  counter-reset: item;
}

.wordpress a {
  @apply break-words;
}

.wordpress ol > li:before {
  content: counter(item) ". ";
  counter-increment: item;
  width: 1.5em;
  display: inline-block;
}

.wordpress ol > li > ol {
  list-style-type: lower-alpha;
}

.wordpress ol > li > ol > li:before {
  content: unset;
}

.wordpress hr.wp-block-separator {
  margin-top: 3rem;
}

.wordpress figure.wp-block-image {
  @apply px-0 py-3;
}

.wordpress .has-custom-border img {
  @apply rounded-lg;
  border: 1px solid #e9e5dd;
}

.wordpress .aligncenter {
  display: flex;
  justify-content: center;
}

.wordpress figcaption.wp-element-caption {
  @apply text-frecBlack/75 my-4 text-sm;
}

.wordpress .wp-block-table td,
.wordpress .wp-block-table th {
  @apply border-frecBlack/10 border p-3 text-left align-top;
}

.wordpress .wp-block-table th {
  @apply bg-frecDarkBeige;
}

.wordpress .has-small-font-size {
  @apply text-sm;
}

@media (max-width: 600px) {
  .wordpress h2.wp-block-heading,
  .wordpress .has-large-font-size {
    font-size: 32px;
    font-weight: 350;
  }

  .wordpress h3.wp-block-heading {
    font-size: 24px;
    font-weight: 400;
  }

  .wordpress h4.wp-block-heading {
    font-size: 20px;
    font-weight: 400;
  }

  .wordpress p {
    font-size: 16px;
  }

  .wordpress figcaption.wp-element-caption {
    font-size: 12px;
  }
}

.calculate-tax-savings-neon-gradient {
  background: radial-gradient(circle at bottom left, #eeeeee, transparent),
    radial-gradient(circle at bottom right, #dcfe52, transparent 50%);
}

footer li a {
  @apply text-frecBlue;
}

footer li a:hover {
  text-decoration: underline;
}

.wordpress .wp-disclosures {
  @apply text-frecBlack/75 !mb-0 pt-6 text-sm;
}
